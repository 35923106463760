module.exports = [
    {
        id: 'Allstars',
        from: new Date(2014, 10),
        to: new Date(2020, 6),
        title: 'Allstars Engineering Group',
        position: 'Design Engineer',
        address: 'Vanha Tampereentie 187\n20380 Turku, Finland',
        image: 'allstars.png',
    },
    {
        id: 'Icepronav',
        from: new Date(2013, 10),
        to: new Date(2014, 10),
        title: 'Icepronav Engineering',
        position: 'Design Engineer',
        address: '19A Portului Street\n800025, Galați, Romania',
        image: 'icepronav.png',
    },
    {
        id: 'Vard',
        from: new Date(2011, 8),
        to: new Date(2013, 10),
        title: 'Vard Braila',
        position: 'Design Engineer',
        address: '1A Celulozei Street\n810282, Brăila, Romania. ',
        image: 'vard.jpg',
    },
    {
        id: 'DMHI',
        from: new Date(2011, 2),
        to: new Date(2011, 8),
        title: 'Daewoo Mangalia Heavy Industries',
        position: 'Naval Engineer',
        address: 'Portului no. 1 Street\n905500 Mangalia, Romania',
        image: 'dmhi.png',
    },
    {
        id: 'Helmers',
        from: new Date(2007, 8),
        to: new Date(2008, 11),
        title: 'SC Helmers SRL',
        position: 'Programmer',
        address: '130B Al.Moruzzi Street\n800223 Galați, Romania',
        image: 'helmers.jpg',
    },
];
