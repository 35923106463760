module.exports = [
    {
        employer: 'Allstars',
        yardNo: '1400',
        name: 'ICON 1',
        owner: 'Royal Caribbean International',
        type: 'Cruise ship',
        image: 'ship.png',
    },
    {
        employer: 'Allstars',
        yardNo: '1395',
        name: 'Costa Toscana',
        owner: 'Costa Crociere',
        type: 'Cruise ship',
        image: 'costa-toscana.jpg',
    },
    {
        employer: 'Allstars',
        yardNo: '1396',
        name: 'Mardi Gras',
        owner: 'Carnival Cruise Lines',
        type: 'Cruise ship',
        image: 'mardi-gras.jpg',
    },
    {
        employer: 'Allstars',
        yardNo: '1394',
        name: 'Costa Smeralda',
        owner: 'Costa Crociere',
        type: 'Cruise ship',
        image: 'costa-smeralda.jpg',
    },
    {
        employer: 'Allstars',
        yardNo: '1393',
        name: 'Mein Schiff 2',
        owner: 'TUI Cruises',
        type: 'Cruise ship',
        image: 'mein-schiff-2.jpg',
    },
    {
        employer: 'Allstars',
        yardNo: '1392',
        name: 'Mein Schiff 1',
        owner: 'TUI Cruises',
        type: 'Cruise ship',
        image: 'mein-schiff-1.jpg',
    },
    {
        employer: 'Allstars',
        yardNo: '514',
        name: 'Yevgeny Primakov',
        owner: 'Sovcomflot',
        type: 'Icebreaking supply vessel',
        image: 'yevgeny-primakov.jpg',
    },
    {
        employer: 'Allstars',
        yardNo: '513',
        name: 'Fedor Ushakov',
        owner: 'Sovcomflot',
        type: 'Icebreaking supply vessel',
        image: 'fedor-ushakov.jpg',
    },
    {
        employer: 'Allstars',
        yardNo: '512',
        name: 'Stepan Makarov',
        owner: 'Sovcomflot',
        type: 'Icebreaking supply vessel',
        image: 'stepan-makarov.jpg',
    },
    {
        employer: 'Allstars',
        yardNo: '511',
        name: 'Gennadiy Nevelskoy',
        owner: 'Sovcomflot',
        type: 'Icebreaking supply vessel',
        image: 'gennadiy-nevelskoy.jpg',
    },
    {
        employer: 'Icepronav',
        yardNo: 'EEP1',
        name: 'Ondina',
        owner: 'Sete Brasil S.A',
        type: "Drillship - 'PRD 12000 Qdrill'",
        image: 'ondina.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '784',
        name: 'Island Crown',
        owner: 'Island Offshore',
        type: 'Platform Supply Vessel',
        image: 'island-crown.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '760',
        name: 'Reimerswaal',
        owner: 'Sheepswerft Gebroeders Kooiman BV',
        type: 'Suction Hopper Dredger',
        image: 'reimerswaal.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '756',
        name: 'Troms Lyra',
        owner: 'Troms Offshore',
        type: 'Platform Supply Vessel',
        image: 'troms-lyra.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '783',
        name: 'Viking Fighter',
        owner: 'NorSea Group',
        type: 'Platform Supply Vessel',
        image: 'viking-fighter.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '788',
        name: 'Gadus Poseidon',
        owner: 'Havfisk',
        type: 'Fishing Vessel',
        image: 'gadus-poseidon.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '789',
        name: 'Gadus Njord',
        owner: 'Havfisk',
        type: 'Fishing Vessel',
        image: 'gadus-njord.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '790',
        name: 'Gadus Neptun',
        owner: 'Havfisk',
        type: 'Fishing Vessel',
        image: 'gadus-neptun.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '785',
        name: 'Island Pride',
        owner: 'Island Offshore',
        type: 'Multi-purpose Support Vessel',
        image: 'island-pride.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '795',
        name: 'Island Duke',
        owner: 'Island Offshore',
        type: 'Platform Supply Vessel',
        image: 'island-duke.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '796',
        name: 'Island Duchess',
        owner: 'Island Offshore',
        type: 'Platform Supply Vessel',
        image: 'island-duchess.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '797',
        name: 'Island Dawn',
        owner: 'Island Offshore',
        type: 'Platform Supply Vessel',
        image: 'island-dawn.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '798',
        name: 'Island Dragon',
        owner: 'Island Offshore',
        type: 'Platform Supply Vessel',
        image: 'island-dragon.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '815',
        name: 'Far Sun',
        owner: 'Farstad Shipping',
        type: 'Platform Supply Vessel',
        image: 'far-sun.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '817',
        name: 'Troms Arcturus',
        owner: 'Troms Offshore',
        type: 'Platform Supply Vessel',
        image: 'troms-arcturus.jpg',
    },
    {
        employer: 'Vard',
        yardNo: 'PRO30',
        name: 'Skandi Urca',
        owner: 'DOF',
        type: 'Anchor Handling Tug Supply Vessel',
        image: 'skandi-urca.jpg',
    },
    {
        employer: 'Vard',
        yardNo: 'PRO33',
        name: 'Skandi Paraty',
        owner: 'DOF',
        type: 'Anchor Handling Tug Supply Vessel',
        image: 'skandi-paraty.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '1048',
        name: 'PSV 09 CD',
        owner: 'Vard',
        type: 'Platform Supply Vessel',
        image: 'ship.png',
    },
    {
        employer: 'Vard',
        yardNo: '931',
        name: 'PSV 08 CD',
        owner: 'Vard',
        type: 'Platform Supply Vessel',
        image: 'ship.png',
    },
    {
        employer: 'Vard',
        yardNo: '814',
        name: 'Island Condor',
        owner: 'Island Offshore',
        type: 'Offshore Service Vessel',
        image: 'island-condor.jpg',
    },
    {
        employer: 'Vard',
        yardNo: '819',
        name: 'Pierre De Fermat',
        owner: 'Orange Marine',
        type: 'Cable Laying & Repair Vessel',
        image: 'pierre-de-fermat.jpg',
    },
];
